.header{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  padding: 15px 6px 7px;
  z-index: 1;

  @media (min-width: 600px) {
    padding: 22px 30px;
    justify-content: flex-end;

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
    transition: padding 0.5s;

    &.header--scrolling {
      padding: 12px 30px 7px;
      .header__logo {
        max-width: 130px;
      }
    }
  }

  &__logo {
    display: none;
    margin-right: auto;
    background: #A9B9B6;
    position: absolute;
    left: 82px;
    top: 0;
    transition: max-width 0.5s;

    @media (min-width: 600px) {
      display: flex;
      max-width: 184px;
      img {
        max-width: 100%;
      }
    }
  }

  &__menuButton {
    border: 0;
    background: transparent;
    width: 31px;

    img {
      max-width: 100%;
    }

    @media (min-width: 600px) {
      display: none;
    }
  }
  &__nav{
    display: none;
    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      margin-right: 10px;

      li {
        padding: 0 10px;
        a {
          font-family: 'Caecilia LT Pro';
          font-weight: 700;
          font-size: 14px;
          color: #586E62;
        }
      }
    }

    @media (min-width: 600px) {
      display: flex;
    }
  }
}
