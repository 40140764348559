.campaigns {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 2em;

  @media (min-width: 600px) {
    flex-direction: row;
    padding: 90px 2em 82px;
    .subTitle {
      width: auto;
      font-size: 36px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    @media (min-width: 600px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 50px;
      margin-left: auto;
    }
    &__item {
      position: relative;
      width: 100%;
      margin-bottom: 40px;
      &__image {
        max-width: 100%;
        img {
          max-width: 100%;
        }
      }

      &__details {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(229, 229, 229, 0.8);
        padding: 17px 0;
        font-family: 'Poppins';
        font-weight: 500;
        font-style: italic;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__action {
          position: absolute;
          top: -47%;
          right: -5%;
        }
      }
    }
  }
}
