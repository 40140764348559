.subTitle {
  font-family: 'Caecilia LT Pro';
  font-weight: 700;
  font-size: 20px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 29px;
  color: #586E62;
  width: 100%;

  @media (min-width: 600px) {
    font-size: 36px;
  }
  &::after {
    position: absolute;
    content: '';
    width:68px;
    background: #C59C98;
    height: 3px;
    left: 0;
    bottom: 0;
  }
}

.h2 {
  font-family: 'Caecilia LT Pro';
  font-weight: 700;
  font-size: 20px;
}



@font-face {
  font-family: 'Caecilia LT Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Caecilia LT Pro 55 Roman'), url('../fonts/CaeciliaLTPro55Roman.woff') format('woff');
}

@font-face {
  font-family: 'Caecilia LT Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Caecilia LT Pro 75 Bold'), url('../fonts/CaeciliaLTPro75Bold.woff') format('woff');
}

// @font-face {
//   font-family: 'Caecilia LT Pro';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Caecilia LT Pro 85 Heavy'), url('../fonts/CaeciliaLTPro85Heavy.woff') format('woff');
// }

