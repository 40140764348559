.input {
  padding: 25px;
  border: 0;
  background: rgba(255, 255, 255, 0.7);
  color: #000;

  &--gray {
    background: rgba(229, 229, 229, 0.8);
    font-family: 'Poppins';
    font-weight: 300;
    font-style: italic;
    font-size: 16px;
    padding: 18px;

    @media (min-width: 600px) {
      padding: 25px;
      font-size: 16px;
    }
  }
}
