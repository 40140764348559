.howWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 2em;
  background: rgba(169, 185, 182, 0.22);
  @media (min-width: 600px) {
    padding: 21px 0;
    .subTitle {
      width: auto;
      &::after {
        left: calc(60% - 68px);
      }
    }
  }

  &__list {
    @media (min-width: 600px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      padding: 0 20px;
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @media (min-width: 600px) {
        padding: 0 20px;
      }
      &__image {
        width: 151px;
        margin-bottom: 33px;
        position: relative;
        @media (min-width: 600px) {
          width: 171px;
        }
        &::before {
          position: absolute;
          border-radius: 50%;
          right: -5px;
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.363);
        }
        img {
          // opacity: .7;
          max-width: 100%;
        }
      }
      &__title {
        font-family: 'Caecilia LT Pro';
        font-weight: 700;
        font-size: 16px;
        color: #C52424;
        margin-bottom: 9px;

        @media (min-width: 600px) {
          font-size: 20px;
        }
      }
      &__text {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        color: #5A5252;
        margin-bottom: 33px;

        @media (min-width: 600px) {
          font-size: 16px;
        }
      }
    }
  }
}
