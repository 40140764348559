.instagram {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 41px 2em;
  background: #FBF6F6;
  .h2 {
    color: #586E62;
  }
}
