#slider {
  max-width: 100%;
  .glider-slide {
    width: 100%;
    a {
      display: flex;
    }
    img {
      width: 100%;
      // object-fit: contain;
    }
  }
  .glider-prev, .glider-next {
    top: 40%;
    height: 30px;
    display: flex;

    @media (min-width: 600px) {
      height: auto;
    }

    img {
      max-height: 100%;
    }
  }
  .glider-prev {
    transform: rotate(180deg);
    left: 10px;
    @media (min-width: 600px) {
      left: 70px;
    }
  }
  .glider-next {
    right: 10px;
    @media (min-width: 600px) {
      right: 70px;
    }
  }
}
