#newsletter {
  background: #FBF6F6;
  padding: 35px 2em;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
  .newsletter {
    &__detail {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      &__title {
        font-size: 18px;
        font-family: "Caecilia LT Pro";
        font-weight: 700;
        color: #586E62;
        @media (min-width: 600px) {
          font-size: 25px;
        }
      }
      &__text {
        font-family: 'Poppins';
        font-size: 12px;
        color: #5A5252;
        @media (min-width: 600px) {
          font-size: 16px;
        }
      }
    }

    &__email {
      max-width: 100%;
      display: flex;
      flex-direction: column;

      @media (min-width: 600px) {
        flex-direction: row;
        width: 50%;
        .input {
          width: 100%;
          margin-bottom: 0!important;
        }
        .button {
          letter-spacing: 5%;
          font-size: 18px;
          max-height: 100%;
        }
      }
      .input {
        margin-bottom: 7px;
        font-family: 'Poppins';
      }
      .button {
        width: fit-content;
        padding: 16px 50px;
      }
    }
  }
}
