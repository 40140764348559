@import './colors.scss';
@import './loading.scss';
@import './typo.scss';
@import './button.scss';
@import './input.scss';
@import './slider.scss';
@import './header.scss';
@import './newsletter.scss';
@import './mission.scss';
@import './howworks.scss';
@import './campaigns.scss';
@import './instagram.scss';
@import './footer.scss';
@import './modal.scss';


body {
  max-width: 100%;
  position: relative;
  max-width: 1280px;
  margin: auto
}
.container {
  padding-top: 60px;
}

a {
  text-decoration: none;
}
