.footer {
  background: #A9B9B6;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: 'Poppins';

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 273px 250px 1fr 1fr;
    grid-template-rows: 1fr 100px;
    gap: 0px 0px;
    grid-template-areas:
      ". . . ."
      "copy copy copy copy";

    .footer {
      &__contact__container {
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
      }
      &__links {
        text-align: left;
        justify-content: center;
      }
      &__copy {
        grid-area: copy;
        height: 100%
      }
      &__social {
        padding-top: 0;
      }
    }
  }

  &__contact__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__logo {
    img {
      max-width: 100%;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    a {
      text-decoration: none;
      color: #fff;
    }
  }
  &__contact {
    width: 68%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px 0;
    margin-top: 40px;
    &__icon {
      max-width: 34px;
      position: absolute;
      left: 12%;
      img {
        max-width: 100%;
      }
    }
  }
  &__social {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 400;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    p {
      width: 100%;
    }
  }
  &__copy {
    margin-top: 50px;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    width: 100%;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background: #fff;
    color: #000;
  }
}
