.mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 41px 2em;


  //

  @media (min-width: 600px) {
    padding: 52px 2em 75px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "titulo titulo imagem imagem"
      "texto texto imagem imagem"
      "texto texto imagem imagem"
  }

  &__image {
    grid-area: imagem;
    max-width: 203px;
    opacity: .7;
    @media (min-width: 600px) {
      max-width: 100%;
      text-align: center;
    }
    img {
      max-width: 100%;
    }
  }

  p {
    grid-area: texto;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #5A5252;

    strong {
      font-weight: 500;
    }

    @media (min-width: 600px) {
      padding-left: 18%;
      text-align: justify;
      align-self: start;
      justify-self: end;
      font-size: 16px;
    }
  }
}
