.modal {
  transform: scale(0);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  z-index: 10000;
  overflow: hidden;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

  @media (min-width: 600px) {
    align-items: center;
  }

  &--show {
    transform: scale(1);
    display: flex;
  }

  &--leave {
    .modal {
      &__title {
        font-family: 'Poppins';
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 30px;
      }
      &__actions {
        display: flex;
        justify-content: center;
        .button {
          font-family: 'Poppins';
          font-weight: 700;
          font-size: 16px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
      &__container {
        &__content {
          font-family: 'Poppins';
          max-width: 600px;
          color: #5A5252;
          font-weight: 400;
          font-size: 22px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          span {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  &--success {
    .modal {
      &__title {
        font-family: 'Poppins';
        font-weight: 400;
        padding-bottom: 20px;
      }
      &__container {
        &__content {
          font-family: 'Poppins';
          max-width: 600px;
          color: #5A5252;
          font-weight: 600;
          font-size: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  &--rounded {
    .modal {
      &__container {
        box-shadow: none;
        align-items: center;
        height: auto;
        padding-bottom: 80px;
        max-height: 100%;
        @media (min-width: 600px) {
          max-width: 820px;
          border-radius: 30px;
          max-height: 95%;
        }
        &__header {

          @media (min-width: 600px) {
            border-radius: 30px;
            padding: 31px 59px 7px;
          }
          background: #fff;
          display: flex;
          justify-content: flex-end;
          margin-left: auto;
          &__button {
            @media (min-width: 600px) {
              height: 55px;
              width: 55px;
              font-size: 25px;
            }
            height: 35px;
            width: 35px;
            position: relative;
            background: #C92469;
            color: #fff;
            border-radius: 50%;
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 15px;
          }
        }

        &__content {
          max-width: 90%;
          @media (min-width: 600px) {
            max-width: 100%;
          }
        }

        form {
          display: flex;
          flex-direction: column;
          @media (min-width: 600px) {
            max-width: 600px;
          }
          .form {
            &__title {
              @media (min-width: 600px) {
                font-size: 26px;
                padding-bottom: 55px;
              }
              font-family: 'Caecilia LT Pro';
              font-weight: 700;
              font-size: 16px;
              color: #C08753;
              padding-bottom: 25px;
            }
            &__footer {
              font-family: 'Poppins';
              display: flex;
              span {
                @media (min-width: 600px) {
                  font-size: 16px;
                }
                font-weight: 500;
                font-style: italic;
                font-size: 14px;
              }
              .button {
                margin-left: auto;
              }
            }

          }
          #result {
            font-size: 18px;
            color: red;
            height: 45px;
          }

          .input {
            @media (min-width: 600px) {
              margin-bottom: 25px;
            }
            margin-bottom: 15px;
          }

          textarea {
            max-height: 100px;
          }

          .input__group {
            display: flex;
            flex-direction: column;
            label {
              @media (min-width: 600px) {
                font-size: 16px;
                margin-bottom: 10px;
              }
              font-family: 'Poppins';
              font-weight: 600;
              letter-spacing: 0.05em;
              color: #586E62;
              font-size: 14px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  &--menu {
    .modal {
      &__container {
        left: 10px;
        height: auto;
        width: 309px;

        &__header {
          height: 133px;
          background: #A9B9B6;
          img {
            max-height: 100%;
          }
          &__button {
            width: 35px;
            height: 35px;
            background: #fff;
            border-radius: 50%;
            color: #C92469;
            font-family: 'Raleway';
            font-weight: 700;
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          ul {
            list-style: none;
            padding: 0;
          }
        }
      }
    }
  }

  &__background {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }

  &__container {
    position: absolute;
    box-shadow: 1px 1px 3px 0px #000;
    background: #fff;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
      width: 80%;
      height: 80%;
    }

    &__header {
      text-transform: uppercase;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 5px;
      background: #586E62;
      color: #fff;
      font-family: 'Caecilia LT Pro';
      font-size: 20px;
      text-align: center;
      &__button {
        cursor: pointer;
        font-family: 'Poppins';
        position: absolute;
        background: transparent;
        color: #fff;
        border: 0;
        right: 5px;
        top: 5px;

      }
    }

    &__content {
      height: auto;
      overflow: auto;
      padding: 10px;
    }
  }
}
